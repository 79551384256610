import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import MobileHeader from './MobileHeader';
import DesktopHeader from './DesktopHeader';
import { useUserDetail } from '../utils/useUserDetail';
import ContactDialog from './ContactDialog';
import { useNotification } from '../utils/useNotification';

const Header = () => {
	useUserDetail();
	useNotification();
	const location = useLocation();

	const [openContact, setOpenContact] = useState(false);

	return (
		<>
			<header className=" bg-headerBg pt-[0.563rem] pb-4 lg:py-3 px-4.5 sticky top-0 z-[99] flex flex-col justify-between ">
				<div className="px-4 flex  items-center justify-between lg:hidden">
					<MobileHeader setOpenContact={setOpenContact} />
				</div>
				<div className="px-36 hidden items-center justify-center lg:flex">
					<DesktopHeader />
				</div>
				<div className="w-full h-2 bg-border-bottom-bg bg-cover bg-no-repeat bg-center absolute bottom-0 "></div>
			</header>
			<ContactDialog
				open={openContact}
				handleOpen={() => setOpenContact(!openContact)}
			/>
		</>
	);
};

export default Header;
