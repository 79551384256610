import React, { useEffect, useRef, useState } from 'react';
import '../../public/MapCurve.css'; // Ensure this includes necessary styles
import FlightPointerIcon from "../../public/flight_pointer.svg"; // Flight icon SVG for animation
import EclipseRedIcon from "../../public/pointer-till-distance-cover.svg"; // Red eclipse icon SVG
import EclipseGrayIcon from "../../public/pointer-remaining-distance.svg"; // Gray eclipse icon SVG

const FlightMapCurve = ({ distance }) => {
    const pathRef = useRef(null);
    const [pathLength, setPathLength] = useState(0);
    const [progress, setProgress] = useState(0); // Normalized progress (0 to 1)

    useEffect(() => {
        if (pathRef.current) {
            const totalLength = pathRef.current.getTotalLength();
            setPathLength(totalLength);
            setProgress(distance / 14000); // Assuming 14000km as the full path length
        }
    }, [distance]);

    // Calculate icon positions dynamically based on the distance
    const coveredLength = pathLength * progress;
    const redIconPosition = pathRef.current?.getPointAtLength(coveredLength);
    const remainingLength = pathLength - coveredLength;
    // Calculate positions for gray icons in the remaining path
    const grayIconMiddlePosition = pathRef.current?.getPointAtLength(coveredLength + remainingLength / 2);
    const grayIconEndPosition = pathRef.current?.getPointAtLength(pathLength);

    return (
        <svg width="400" height="200" viewBox="0 0 400 200">
            {/* Full path - solid */}
            <path
                ref={pathRef}
                d="M390,150 C290,10 110,10 10,150" // Example curve; replace with your actual SVG path
                fill="none" stroke="gray" strokeWidth="2" />
            {/* Covered path - dashed red */}
            <path
                d="M390,150 C290,10 110,10 10,150" // Same path for the visual effect
                fill="none" stroke="red" strokeWidth="2" strokeDasharray="5,5"
                strokeDashoffset={(1 - progress) * pathLength}
            />
            {/* Icons */}
            <image href={EclipseRedIcon} x={redIconPosition?.x - 10} y={redIconPosition?.y - 10} height="20" width="20" />
            <image href={EclipseGrayIcon} x={grayIconMiddlePosition?.x - 10} y={grayIconMiddlePosition?.y - 10} height="20" width="20" />
            <image href={EclipseGrayIcon} x={grayIconEndPosition?.x - 10} y={grayIconEndPosition?.y - 10} height="20" width="20" />
            {/* Flight pointer icon animated along the path */}
            <image className="flight-icon-animation" href={FlightPointerIcon} x={redIconPosition?.x - 10} y={redIconPosition?.y - 10} height="20" width="20" />
        </svg>
    );
};

export default FlightMapCurve;
