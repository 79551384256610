import ButtonDefault from './Button';
import { Button } from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';
import FancodeIcon from '../../public/fancode_icon.svg';
import Fancode499Icon from '../../public/fancode_499_icon.svg';
import Typography from './Typography';
import BottomBorderIcon from '../../public/bottom_border_icon.svg';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import SocialShareButton from './share/SocialShareButton';
import { gaEvents } from '../utils/gaEvents';
import { useCDP } from '../utils/useCDP';

const PopupGift = ({
	imgSrc,
	text,
	subText,
	btnText,
	btnOnClick,
	labelBtnText,
	share,
	expiry,
	fanCodeIcon,
	couponCode,
	redeemLink,
	referFriendHanlder,
	remaining_expiry_hours,
	expiry_date,
	giftClasses = 'bg-giftBg',
	winMessage,
	winImage,
	winCode,
	redeemEvent,
	btnOnClickHandler = () => {},
	shareBtnTxt,
	copyTextTrans = 'COPY CODE',
}) => {
	const { i18n } = useTranslation();
	const navigate = useNavigate();
	const [copyTxt, setCopyTxt] = useState(copyTextTrans);
	const sendCDPData = useCDP();

	const copyCouponCode = (str) => {
		navigator.clipboard
			.writeText(str)
			.then(() => {
				setCopyTxt(t('COPIED'));
				setTimeout(() => setCopyTxt(copyTextTrans), 1000);
				// Optionally, you can show a success message to the user
			})
			.catch((err) => {
				console.error('Failed to copy text:', err);
				// Optionally, you can show an error message to the user
			});
	};

	const onRedeemClick = () => {
		// GA Event
		window.dataLayer.push(gaEvents[redeemEvent]);

		if (winCode === 2) {
			sendCDPData('Click', 'Redeem_Fancode999');
		} else {
			sendCDPData('Click', 'Redeem_Fancode499');
		}
	};

	return (
		<div className="rounded-lg bg-inputBg font-thumbsUp">
			<div
				className={`rounded-t-lg ${giftClasses}  flex flex-col justify-between items-center mb-3 w-full ${
					winCode === 1 ? 'h-32' : ''
				} relative`}
			>
				<div className="w-[11.5rem] h-[8.68rem] flex-1 flex justify-center items-center">
					<img src={imgSrc} className={`rounded-lg w-full`} />
				</div>
				{fanCodeIcon && (
					<div className="absolute top-2.5 left-2.5 w-[4.438rem] h-6">
						<img src={FancodeIcon} alt="fancode499" className="w-full h-full" />
					</div>
				)}
				<div className="w-full h-2 bg-border-bottom-bg bg-cover bg-no-repeat bg-center"></div>
			</div>
			<div className="flex flex-col justify-normal items-center px-2">
				<Typography
					variant="h3"
					type="text24"
					text={text}
					customClasses="mb-1 text-center"
				/>
				<h3 className="font-medium text-voucherSubText mb-4 text-center ">
					{subText}
				</h3>

				<div className="flex flex-col justify-normal items-center w-full">
					{couponCode && (
						<div className="border-2 pl-4 border-dashed border-otherTextColor  font-thumbsUp w-3/4 mb-5 flex justify-between items-center gap-3 rounded-lg">
							<div className="py-2">
								<Typography variant="h3" type="text24" text={couponCode} />
							</div>
							<div
								className="py-2 px-4 bg-otherTextColor rounded-lg"
								onClick={() => copyCouponCode(couponCode)}
							>
								<Typography
									variant="h3"
									type={
										i18n.language === 'en'
											? 'text20'
											: i18n.language !== 'ka' && i18n.language !== 'te'
											? 'text14'
											: 'text12'
									}
									text={copyTxt}
									customClasses="text-white"
								/>
							</div>
						</div>
					)}
					{redeemLink ? (
						<div className=" mb-2">
							<ButtonDefault
								text={btnText}
								customClasses="text-xl font-normal px-3 py-3"
								actionLink={redeemLink}
								onClick={onRedeemClick}
							/>
						</div>
					) : (
						<div className="  pb-3">
							<ButtonDefault
								text={btnText}
								customClasses="text-xl font-normal px-1 py-2"
								onClick={btnOnClick}
							/>
						</div>
					)}
				</div>

				{labelBtnText && (
					<div className="  text-center mt-2 pb-2">
						<span
							className={`text-gray ${
								i18n.language === 'en' ? 'text-[1rem]' : 'text-xs'
							}`}
						>
							{labelBtnText}
						</span>
						<span
							className={`text-errorText ${
								i18n.language === 'en' ? 'text-[1rem]' : 'text-xs'
							}`}
						>
							{' '}
							{expiry}
						</span>
					</div>
				)}

				{redeemLink && referFriendHanlder && (
					<SocialShareButton
						handleShare={referFriendHanlder}
						title={winMessage}
						text=""
						url=""
						btnText={shareBtnTxt}
						file={winImage}
						winCode={winCode}
						sendCDPData={sendCDPData}
						classesName="border-2 mt-3 font-medium text-1xl border-otherTextColor text-otherTextColor  p-2 font-thumbsUp w-2/3 mb-5"
					/>
				)}
			</div>
		</div>
	);
};

export default PopupGift;
