
import Typography from './Typography';
import SaidIcon from '../../public/said_icon.svg';

const ClaimRedeemFail = () => {

	return (
		<>
			<div className="flex flex-col justify-start items-center text-center">
				<div className="w-9 h-9 mb-2 lg:w-20 lg:h-20 lg:mb-5">
					<img src={SaidIcon} className="w-full h-full" />
				</div>
				<Typography
					variant="h2"
					type="text24"
					text="Oops!"
					customClasses="mb-4 text-center  lg:text-[4.25rem] lg:leading-normal"
				/>
				<h3 className="text-center text-sm  leading-[1.125rem] font-thumbsUp  lg:text-[2.375rem] lg:leading-normal">
					The details submitted by you did not pass the verification step, and your seat aboard Toofan stands forfeited.
				</h3>
			</div>
		</>
	);
};

export default ClaimRedeemFail;
