import { useTranslation } from 'react-i18next';
import Button from '../components/Button';
import GiftIcon1 from '../../public/gift_icon_1.svg';
import GiftIcon2 from '../../public/gift_icon_2.svg';
import FlightMapCurve from '../components/FlightMapCurve';
import FlightMapCurveSvg from '../components/FlightMapCurveSvg';

const ContestOver = () => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col justify-center items-center h-screen  ">
			<FlightMapCurve distance="6000" />
			<FlightMapCurveSvg distance="4000" />
			<h1 className="text-white text-3xl font-normal leading-7 mb-1">
				{t('contest_over')}
			</h1>
			<h2 className="text-white text-xl font-normal leading-5">
				{t('thank_you_visit_page')}
			</h2>
			<h3 className="text-white text-xs font-light leading-6 mb-3">
				{t('participate_other_giveaways')}
			</h3>
			<div className="">
				<Button text={t('winner_list')} customClasses="text-base p-2" />
			</div>
			<div className="w-24 h-20 absolute bottom-14 left-0">
				<img src={GiftIcon1} />
			</div>
			<div className="w-8 h-20 absolute bottom-16 right-0">
				<img src={GiftIcon2} />
			</div>
		</div>
	);
};

export default ContestOver;
