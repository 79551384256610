import { useEffect, useCallback, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Button from '../components/Button';
import Input from '../components/Input';
import InputErrorMessage from '../components/InputErrorMessage';
import { useApiCall } from '../utils/useApiCall';
import Loader from '../components/LoaderV1';
import { AuthContext, useAuthContext } from '../context/auth-context';
import { useCampaignContext } from '../context/campaign-context';
import RightHandBatterIcon from '../../public/right_bat.svg';
import { gaEvents } from '../utils/gaEvents';
import { useUserContext } from '../context/user-context';

const AffiliateInviteForm = ({ handleOpen }) => {
	const navigate = useNavigate();
	const { isLoggedIn } = useAuthContext();
	const { historyBackOption, setHistoryBackOption } = useUserContext();
	const {
		openLoginForm,
		setOpenLoginForm,
		setOpenAffiliateForm,
		setPopupInfo,
		setUserInfoPopup,
	} = useCampaignContext();
	const { token, setQueryUser } = useContext(AuthContext);
	const { t } = useTranslation('affiliate');
	useEffect(() => {
		if (!isLoggedIn) {
			setOpenAffiliateForm(false);
			setOpenLoginForm(!openLoginForm);
			setHistoryBackOption({
				...historyBackOption,
				isDialogOpen: true,
				redirectLink: () => {
					setOpenAffiliateForm(true);
				},
			});
		}
	}, [isLoggedIn]);

	const { isLoading, error: apiError, sendRequest } = useApiCall();

	const yupSchema = useCallback(
		yup.object({
			mobile_number: yup
				.string()
				.matches(/^\d{10}$/, t('mobile_required'))
				.min(10, t('mobile_required')),
			email_id: yup.string().nullable().email(t('email_optional')),
		}),
		['email_id', 'mobile_number']
	);

	const {
		handleSubmit,
		formState: { errors },
		control,
		reset,
	} = useForm({
		resolver: yupResolver(yupSchema),
	});

	const submitHandler = async (data) => {
		const { full_name, email_id, mobile_number, dob } = data;

		try {
			const response = await sendRequest(
				`${process.env.API_BASE_URL}send-invite`,
				'POST',
				JSON.stringify({
					email: email_id || undefined,
					mobile_no: +mobile_number,
					isd_code: 91,
				}),
				{
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				}
			);

			if (response.success) {
				// GA Event
				window.dataLayer.push(gaEvents['TUICC24_InviteFriends_Submit']);
				handleOpen();
				setUserInfoPopup(true);
				setPopupInfo({
					customHeading: t('thank_you'),
					customSubHeadingText: t('thank_you_h3', {
						code: response?.data?.invite_code,
					}),
					cstBtnText: response?.data?.invite_code,
					cstLabelBtnText: t('thank_you_info_text'),
				});
			} else {
				throw new Error(response.message);
			}
		} catch (error) {
			console.error(`Affiliate API error>>>`, error);
		}
	};
	return (
		<div className="flex flex-col justify-between items-center font-thumbsUp ">
			{isLoading && <Loader />}
			<div className="items-center mb-3">
				<img src={RightHandBatterIcon} alt="icon" />
			</div>
			<div className="mb-4 text-center ">
				<h2 className="text-white text-center text-3xl font-medium leading-8">
					{t('heading_h2')}
				</h2>

				<h2 className="text-white font-medium text-2xl  leading-7 text-center">
					{t('heading_h3')}
				</h2>
				<h4 className="text-base text-center text-white font-normal leading-7 mb-2">
					{t('invite_code_text')}
				</h4>
			</div>
			<form onSubmit={handleSubmit(submitHandler)} className="w-full">
				<div className="mb-6">
					<Controller
						name="mobile_number"
						control={control}
						defaultValue={''}
						render={({ field: { ref, ...rest } }) => (
							<Input
								{...rest}
								isValid={!errors?.mobile_number}
								placeholder={t('friend_mobile_number')}
							/>
						)}
					/>

					<InputErrorMessage text={errors?.mobile_number?.message} />
				</div>
				<div className="mb-6">
					<Controller
						name="email_id"
						control={control}
						defaultValue={''}
						render={({ field: { ref, ...rest } }) => (
							<Input
								{...rest}
								isValid={!errors?.email_id}
								placeholder={t('friend_email')}
							/>
						)}
					/>
					<InputErrorMessage text={errors?.email_id?.message} />
				</div>
				{apiError && (
					<InputErrorMessage text={t(apiError)} customClasses="mb-1" />
				)}
				<Button
					text={t('send_invite_btn')}
					customClasses="lg:text-3xxl p-3 text-white"
				/>
			</form>
			<div className="w-2/2  text-center mt-3 text-white">
				<Link
					to="https://017533-content-dev.s3.ap-south-1.amazonaws.com/privacypolicy/TU+2024-+Privacy+Policy.pdf"
					target="_blank"
				>
					<button
						onClick={() => {
							handleOpen();
						}}
					>
						{t('privacy_policy')}
					</button>
				</Link>
			</div>
		</div>
	);
};

export default AffiliateInviteForm;
