import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import LeftArrowIcon from '../../public/left_arrow.svg';
import RightArrowIcon from '../../public/right_arrow.svg';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const { t } = useTranslation();

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const delta = windowWidth < 768 ? 1 : 2; // Adjust number of pages based on screen width
	let range = [];

	// Calculate range of page numbers to display
	for (
		let i = Math.max(2, currentPage - delta);
		i <= Math.min(totalPages - 1, currentPage + delta);
		i++
	) {
		range.push(i);
	}

	// Adjustments for ellipses and boundaries
	if ('...' !== range[0]) {
		if (currentPage - delta > 2) {
			range.unshift('...');
		}
		range.unshift(1);
	}

	if ('...' !== range[range.length - 1]) {
		if (currentPage + delta < totalPages - 1) {
			range.push('...');
		}
		if (totalPages !== 1) {
			range.push(totalPages);
		}
	}

	return (
		<div className="flex justify-center mt-4 space-x-1 ">
			<button
				onClick={() => onPageChange(1)}
				className="px-2 text-black font-bold text-[.75rem]"
				disabled={currentPage === 1}
			>
				{t('first')}
			</button>
			<button
				onClick={() => onPageChange(Math.max(1, currentPage - 1))}
				className="px-2 text-black  text-[.75rem] hover:bg-blue-100 rounded"
			>
				<img src={LeftArrowIcon} alt="previous" />
			</button>
			{range.map((page, index) => (
				<button
					key={index}
					onClick={() => onPageChange(page)}
					className={`px-2 font-bold text-[.75rem]  ${
						currentPage === page
							? 'bg-otherTextColor text-white'
							: 'text-black hover:bg-otherTextColor'
					} rounded`}
					disabled={page === '...'}
				>
					{page}
				</button>
			))}
			<button
				onClick={() => onPageChange(Math.min(totalPages, currentPage + 1))}
				className="px-2 text-black-700 text-[.75rem] rounded"
			>
				<img src={RightArrowIcon} alt="next" />
			</button>
			<button
				onClick={() => onPageChange(totalPages)}
				className="px-2 text-black-700 text-[.75rem] rounded"
				disabled={currentPage === totalPages}
			>
				{t('last')}
			</button>
		</div>
	);
};

export default Pagination;
