{
	"win_1": {
		"headingText": "आपने तूफ़ानी फैन क्लब में जगह बना ली है!",
		"subHeadingText": "आपने तूफ़ान में एक सीट जीत ली! वेस्ट इंडीज जाने के लिए शानदार उड़ान हेतु तैयार हो जाएं",
		"text": "",
		"icon": "flight_won_icon",
		"btnText": "अभी क्लेम करें",
		"btnSubText": "क्लेम करने की अंतिम तारीख:",
		"timerDisplay": " {{datetime}}"
	},
	"win_2": {
		"headingText": "बधाई हो!",
		"subHeadingText": "You have won Cricket Jersey worth ₹999 from FanCode!",
		"text": "",
		"icon": "win_icon",
		"btnText": "अभी रिदीम करें",
		"btnSubText": "Last date to redeem:",
		"timerDisplay": " {{datetime}}"
	},
	"win_3": {
		"headingText": "Congratulations!",
		"subHeadingText": "You have won Toofani merch worth ₹499 from FanCode!",
		"text": "",
		"icon": "win_icon",
		"btnText": "अभी रिदीम करें",
		"btnSubText": "Last date to redeem:",
		"timerDisplay": " {{datetime}}"
	},
	"win_check": {
		"headingText": "बस एक जांच और!",
		"subHeadingText": "We are verifying the details you’ve submitted & will notify you on your eligibility to board Toofan within 5 seconds, on this site as well as through SMS or WhatsApp. Sit tight!",
		"text": "",
		"icon": "flight_win_check"
	},
	"win_check_waiting": {
		"headingText": "बस एक जांच और!",
		"subHeadingText": "",
		"text": "We are verifying the details you’ve submitted & will notify you on your eligibility to board Toofan within 3 business days, on this site as well as through SMS or WhatsApp. Sit tight!",
		"icon": "flight_win_check"
	},
	"valid_code": {
		"headingText": "वाह, तूफ़ान!",
		"subHeadingText": "आपने {{points}} कि.मी. जमा कर लिया है। आप तूफ़ान पर सवार होने के अपने मौके से केवल {{remaining_km}} कि.मी. ही दूर हैं!",
		"text": "",
		"icon": "points_win_flight_icon.svg"
	},
	"total_limit_exceeded": {
		"headingText": "It’s the Bench for You!",
		"subHeadingText": "You have entered multiple invalid codes today. Come back tomorrow to try again.",
		"text": "",
		"icon": "warn_icon"
	},
	"already_win": {
		"headingText": "पहले ही जीत चुके हैं!",
		"subHeadingText": "आप लकी ड्रा में पहले ही जीत चुके हैं",
		"text": "",
		"icon": "warn_icon"
	},
	"valid_code_limit_exceeded": {
		"headingText": "It’s the Bench for You!",
		"subHeadingText": "",
		"text": "You have entered multiple invalid codes today. Come back tomorrow to try again."
	},
	"claimed": {
		"headingText": "Congratulations!",
		"subHeadingText": "You’ve successfully claimed your ticket aboard Toofan Air with our fan squad",
		"text": "",
		"btnText": "Share",
		"icon": "claimed_icon"
	},
	"after_claimed": {
		"headingText": "बस एक जांच और!",
		"subHeadingText": "हम आपके द्वारा जमा किए गए विवरणों का वेरिफिकेशन कर रहे हैं और आपको इस साइट के साथ ही एसएमएस या व्हाट्सएप के द्वारा 10 कारोबारी दिवस के भीतर तूफान में शामिल होने की आपकी पात्रता के बारे में सूचित करेंगे। तैयार रहें!",
		"text": "",
		"btnText": "",
		"icon": "flight_win_check"
	},
	"claimed_reward": {
		"headingText": "Congratulations!",
		"subHeadingText": "You’ve successfully claimed your Toofani Reward",
		"text": "",
		"btnText": "Share",
		"icon": "claimed_icon"
	},
	"claim_now": {
		"headingText": "समय निकल रहा है!",
		"subHeadingText": "{{timer}} को तूफान में अपनी सीट का दावा करने वाली विंडो की समय अवधि समाप्त हो जाएगी",
		"text": "",
		"btnText": "अभी क्लेम करें",
		"icon": "timer_icon"
	},
	"claim_expired": {
		"headingText": "आप रन आउट हो गए!",
		"subHeadingText": "अपने रिवार्ड पर आपके क्लेम की समय अवधि समाप्त हो गई।",
		"text": "भाग लेने के लिए आपको धन्यवाद। ",
		"icon": "said_icon"
	},
	"not_win_for_the_day": {
		"headingText": "यह मेडेन ओवर है!",
		"subHeadingText": "उम्मीद बनाए रखें, आप अब भी भी गेम में हैं। कल दोबारा आएं और जांचें कि कहीं आपने तूफान की सीट जीती हो!",
		"text": "",
		"icon": "said_icon"
	},
	"exit_claim_popup": {
		"headingText": "अपने मेडल क्लेम करें ",
		"subHeadingText": "अपने प्राइज पर आपका क्लेम {{hours}} घंटे में समाप्त हो जाएगा।",
		"text": "क्या सच में एग्जिट करना चाहते/चाहती हैं?",
		"icon": "claim_expired_icon",
		"btnTextYes": "हां",
		"btnTextNo": "नहीं",
		"confirm": true
	},
	"exit_next_win_for_new": {
		"headingText": "रूकें! ",
		"subHeadingText": "आज अगला विजेता आप हो सकते/सकती हैं!",
		"text": "क्या सच में एग्जिट करना चाहते/चाहती हैं? ",
		"icon": "raise_hand_icon",
		"btnTextYes": "हां",
		"btnTextNo": "नहीं",
		"confirm": true
	},
	"exit_next_win": {
		"headingText": "आप विजयी स्कोर हासिल करने के करीब हैं! ",
		"subHeadingText": "दूसरा यूनिक कोड दर्ज करें और जीत का एक अवसर पाएं! ",
		"text": "क्या सच में एग्जिट करना चाहते/चाहती हैं?  ",
		"icon": "raise_hand_icon",
		"btnTextYes": "हां",
		"btnTextNo": "नहीं",
		"confirm": true
	},
	"affiliate_signup_success": {
		"headingText": "शाबाश कप्तान",
		"subHeadingText": "",
		"text": "आपने 100 किलोमीटर एकत्र कर लिया है ",
		"icon": "right_bat"
	},
	"default": {
		"headingText": "Error!",
		"subHeadingText": "Not found!",
		"text": ""
	},
	"qualified_for_lucky_draw": {
		"headingText": "Already qualified!",
		"subHeadingText": "You have already qualified for the lucky draw",
		"text": ""
	}
}
