{
	"heading_h2": "પાર્ટનરશિપ હોય તો આવી!",
	"heading_h3": "તમારા મિત્રને ભાગ લેવા આમંત્રિત કરો",
	"invite_code_text": "જ્યારે તમારો મિત્ર સાઇન અપ કરે અને તમારા આમંત્રણ કોડનો ઉપયોગ કરે ત્યારે 100kms મેળવો",
	"friend_mobile_number": "તમારા મિત્રનો મોબાઈલ નંબર દાખલ કરો",
	"friend_email": "તમારા મિત્રનુ ઈમેલ દાખલ કરો (વૈકલ્પિક)",
	"send_invite_btn": "સબમિટ કરો",
	"privacy_policy": "ગોપનીયતા નીતિ",
	"icon": "right_bat",
	"mobile_required": "કૃપા કરીને માન્ય નંબર દાખલ કરો",
	"email_optional": "કૃપા કરીને માન્ય ઇમેઇલ દાખલ કરો",
	"Number already in use, Please refer another": "નંબર પહેલેથી ઉપયોગમાં છે, કૃપા કરીને બીજો સંદર્ભ લો",
	"thank_you": "આભાર!",
	"thank_you_h3": "કોડ સાથે તમારા મિત્રને આમંત્રણ મોકલવામાં આવ્યું છે",
	"thank_you_info_text": "એકવાર તમારો મિત્ર સાઇન અપ કરશે અને તેમના અનન્ય ઈન્વાઈટ કોડનો ઉપયોગ કરશે ત્યારે તમને 100kms મળશે.",
	"enter_invite_code": "ઈન્વાઈટ કોડ દાખલ કરો",
	"affiliate_signup_h3": "તમારા મિત્રનો 6-અંકનો ઈન્વાઈટ કોડ દાખલ કરો અને 100kms મેળવો",
	"affiliate_signup_btn": "સબમિટ કરો",
	"Please enter a valid code": "કૃપા કરીને માન્ય કોડ દાખલ કરો",
	"my_referrals": "મારા રેફરલ્સ",
	"accepted": "સ્વીકૃત",
	"pending": "બાકી",
	"users": "વપરાશકર્તાઓ",
	"status": "સ્થિતિ",
	"send_reminder": "રીમાઇન્ડર મોકલો",
	"refer_another": "અન્યને સંદર્ભિત કરો",
	"Referral not found": "રેફરલ મળ્યુ નથી"
}
