import React, { useState, useEffect, useRef } from 'react';
import '../../public/MapCurve.css';
import { useAuthContext } from '../context/auth-context';

const FlightMapCurveSvg = ({ milestone }) => {
	const [activeJourney, setActiveJourney] = useState(0);

	const { isLoggedIn } = useAuthContext();

	useEffect(() => {
		let timer;

		if (milestone && isLoggedIn) {
			timer = setTimeout(() => {
				milestone && setActiveJourney(milestone);
			}, 3000);
		} else {
			setActiveJourney(0);
		}

		return () => {
			timer && clearTimeout(timer);
		};
	}, [milestone, isLoggedIn]);

	// References to the animateMotion elements
	const animation1Ref = useRef(null);
	const animation2Ref = useRef(null);
	const animation3Ref = useRef(null);

	// Function to set href attribute for compatibility with React
	const setHref = (animationPathRef, pathId) => {
		if (animationPathRef.current) {
			animationPathRef.current.setAttribute('href', pathId);
		}
	};

	// Effect hook to safely start the animation based on the activeJourney state
	useEffect(() => {
		const animations = {
			1: animation1Ref.current,
			2: animation2Ref.current,
			3: animation3Ref.current,
		};

		if (animations[activeJourney]) {
			animations[activeJourney].beginElement();
		}
	}, [activeJourney]);

	return (
		<div>
			<svg
				id="mySvg"
				width="220"
				height="100"
				viewBox="0 0 215 80"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g id="pathGroup">
					<path
						id="myPath"
						d="M1 27.6172C27.5 1.11518 33.476 2.75594 60.5 1.11695C93.5 -0.884452 121.5 23.1154 114 49.1162C108.421 68.4586 85 68.1164 78 56.1163C69.833 42.1163 71 13.1159 119 9.6159C157.4 6.81588 174.5 16.6172 181.5 21.6172C188.5 26.6172 197.5 38.6172 202.5 55.6172"
						stroke="#202562"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeDasharray="4 4"
					/>
					<path
						id="myNewPath1"
						d="M 135.206 9.154 C 162.408 9.71 175.574 17.384 181.5 21.617 C 188.5 26.617 197.5 38.617 202.5 55.617"
						stroke="none"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeDasharray="4 4"
					/>
					<path
						id="myNewPath2"
						d="M 114 49.116 C 108.421 68.459 85 68.116 78 56.116 C 69.833 42.116 71 13.116 119 9.616 C 157.4 6.816 174.5 16.617 181.5 21.617 C 188.5 26.617 197.5 38.617 202.5 55.617"
						stroke="none"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeDasharray="4 4"
					/>
					<circle
						id="dot_1"
						cx="4"
						cy="7"
						r="3.5"
						transform="matrix(-1 0 0 1 170 7)"
						fill="#717171"
						stroke="#202562"
					/>
					<circle
						id="dot_2"
						cx="28"
						cy="3"
						r="3.5"
						transform="matrix(-1 0 0 1 120 61)"
						fill="#717171"
						stroke="#202562"
					/>
					<circle
						id="dot_3"
						cx="4"
						cy="-7"
						r="3.5"
						transform="matrix(-1 0 0 1 8 32)"
						fill="#717171"
						stroke="#202562"
					/>
				</g>

				{/* Plane groups with conditional display */}
				<g
					id="myPlaneGroup_1"
					style={{
						display: activeJourney === 1 ? 'block' : 'none',
					}}
				>
					<path
						id="myPlane_1"
						d="M1.04009 3.85829L1.0321 3.87762C0.695096 4.8496 2.4571 5.6849 2.4571 5.6849L5.51209 7.2669L6.2291 10.0667C5.5171 9.9389 4.92811 10.0704 4.79211 10.4328C4.61611 10.9022 5.2661 11.5805 6.2441 11.9479C6.4151 12.0122 6.5851 12.0623 6.7501 12.0994L7.3001 14.2492L9.0311 15.062L9.0581 8.534L14.8151 10.0981L15.3901 12.4389L16.1831 12.7376L18.2771 7.1638L17.4831 6.8663L15.5091 8.2499L10.1461 5.6368L14.4231 0.705454L12.5851 0.177734L10.7561 1.43352C10.6071 1.353 10.4471 1.2791 10.2761 1.21482C9.2971 0.847425 8.3621 0.930004 8.1851 1.39929C8.0491 1.76174 8.4061 2.24863 9.0261 2.62088L6.6431 4.25637L3.3021 3.43573C3.3021 3.43573 1.42509 2.90444 1.04009 3.85829Z"
						fill="#CE0E2D"
						style={{ transform: 'translate(4px, -4px) rotate(-15deg)' }}
					>
						<animateMotion
							id="animation_1"
							dur="5s"
							repeatCount="1"
							keyPoints="1;0"
							keyTimes="0;1"
							calcMode="linear"
							rotate="auto"
							fill="freeze"
						>
							<mpath id="animation-path_1" href="#myNewPath1" />
						</animateMotion>
					</path>
				</g>

				<g
					id="myPlaneGroup_2"
					style={{ display: activeJourney === 2 ? 'block' : 'none' }}
				>
					<path
						id="myPlane_2"
						d="M1.04009 3.85829L1.0321 3.87762C0.695096 4.8496 2.4571 5.6849 2.4571 5.6849L5.51209 7.2669L6.2291 10.0667C5.5171 9.9389 4.92811 10.0704 4.79211 10.4328C4.61611 10.9022 5.2661 11.5805 6.2441 11.9479C6.4151 12.0122 6.5851 12.0623 6.7501 12.0994L7.3001 14.2492L9.0311 15.062L9.0581 8.534L14.8151 10.0981L15.3901 12.4389L16.1831 12.7376L18.2771 7.1638L17.4831 6.8663L15.5091 8.2499L10.1461 5.6368L14.4231 0.705454L12.5851 0.177734L10.7561 1.43352C10.6071 1.353 10.4471 1.2791 10.2761 1.21482C9.2971 0.847425 8.3621 0.930004 8.1851 1.39929C8.0491 1.76174 8.4061 2.24863 9.0261 2.62088L6.6431 4.25637L3.3021 3.43573C3.3021 3.43573 1.42509 2.90444 1.04009 3.85829Z"
						fill="#CE0E2D"
						style={{ transform: 'translate(4px, -4px) rotate(15deg)' }}
					>
						<animateMotion
							id="animation_2"
							dur="10s"
							repeatCount="1"
							keyPoints="1;0"
							keyTimes="0;1"
							calcMode="linear"
							rotate="auto"
							fill="freeze"
						>
							<mpath id="animation-path_2" href="#myNewPath2" />
						</animateMotion>
					</path>
				</g>

				<g
					id="myPlaneGroup_3"
					style={{ display: activeJourney === 3 ? 'block' : 'none' }}
				>
					<path
						id="myPlane_3"
						d="M1.04009 3.85829L1.0321 3.87762C0.695096 4.8496 2.4571 5.6849 2.4571 5.6849L5.51209 7.2669L6.2291 10.0667C5.5171 9.9389 4.92811 10.0704 4.79211 10.4328C4.61611 10.9022 5.2661 11.5805 6.2441 11.9479C6.4151 12.0122 6.5851 12.0623 6.7501 12.0994L7.3001 14.2492L9.0311 15.062L9.0581 8.534L14.8151 10.0981L15.3901 12.4389L16.1831 12.7376L18.2771 7.1638L17.4831 6.8663L15.5091 8.2499L10.1461 5.6368L14.4231 0.705454L12.5851 0.177734L10.7561 1.43352C10.6071 1.353 10.4471 1.2791 10.2761 1.21482C9.2971 0.847425 8.3621 0.930004 8.1851 1.39929C8.0491 1.76174 8.4061 2.24863 9.0261 2.62088L6.6431 4.25637L3.3021 3.43573C3.3021 3.43573 1.42509 2.90444 1.04009 3.85829Z"
						fill="#CE0E2D"
						style={{ transform: 'translate(4px, -4px) rotate(-15deg)' }}
					>
						<animateMotion
							id="animation_3"
							dur="15s"
							repeatCount="1"
							keyPoints="1;0"
							keyTimes="0;1"
							calcMode="linear"
							rotate="auto"
							fill="freeze"
						>
							<mpath id="animation-path_3" href="#myPath" />
						</animateMotion>
					</path>
				</g>
			</svg>

			<div className="actions">
				<button onClick={() => setActiveJourney(1)}>Go to point 1</button>
				<button onClick={() => setActiveJourney(2)}>Go to point 2</button>
				<button onClick={() => setActiveJourney(3)}>Go to point 3</button>
			</div>
		</div>
	);
};

export default FlightMapCurveSvg;
