import { useEffect } from 'react';

const loadFacebookPixelScript = (pixelId) => {
	// Check if the fbq function already exists or the script has been loaded
	if (window.fbq && window.fbPixelScriptLoaded) return;

	window.fbq = function () {
		if (window.fbq.callMethod) {
			window.fbq.callMethod.apply(window.fbq, arguments);
		} else {
			(window.fbq.queue = window.fbq.queue || []).push(arguments);
		}
	};
	window.fbq.push = window.fbq;
	window.fbq.loaded = true;
	window.fbq.version = '2.0';
	window.fbq.queue = [];

	const script = document.createElement('script');
	script.async = true;
	script.src = `https://connect.facebook.net/en_US/fbevents.js`;
	script.onload = () => {
		window.fbPixelScriptLoaded = true;
	}; // Set flag when script loads
	document.head.appendChild(script);

	window.fbq('init', pixelId);
};

const FacebookPixel = ({ pixelId }) => {
	useEffect(() => {
		loadFacebookPixelScript(pixelId);
	}, [pixelId]);

	useEffect(() => {
		if (window.fbq) {
			try {
				window.fbq('track', 'PageView');
				// Place other fbq('track', ...) calls here, or in other useEffect hooks based on app logic
			} catch (error) {
				console.error('Error tracking PageView with FB Pixel:', error);
			}
		}
	}, []);

	return null; // This component doesn't render anything
};

export default FacebookPixel;
