{
	"win_1": {
		"headingText": "You’ve made it to the Toofani Fan Club!",
		"subHeadingText": "You won a seat Aboard Toofan to the ICC 2024 Games in the West Indies",
		"text": "",
		"icon": "flight_won_icon",
		"btnText": "ఇప్పుడు క్లైమ్ చేసుకోండి",
		"btnSubText": "Claim within:",
		"timerDisplay": " {{datetime}}"
	},
	"win_2": {
		"headingText": "శుభాభినంధనలు!",
		"subHeadingText": "You have won Cricket Jersey worth ₹999 from FanCode!",
		"text": "",
		"icon": "win_icon",
		"btnText": "ఇప్పుడే రిడీమ్ చేసుకోండి",
		"btnSubText": "రిడీమ్ చేసుకోవడానికి చివరి తేదీ:",
		"timerDisplay": " {{datetime}}"
	},
	"win_3": {
		"headingText": "శుభాభినంధనలు!",
		"subHeadingText": "You have won Toofani merch worth ₹499 from FanCode!",
		"text": "",
		"icon": "win_icon",
		"btnText": "ఇప్పుడే రిడీమ్ చేసుకోండి",
		"btnSubText": "రిడీమ్ చేసుకోవడానికి చివరి తేదీ:",
		"timerDisplay": " {{datetime}}"
	},
	"win_check": {
		"headingText": "మరొక్క సారి పరీక్షించండి!",
		"subHeadingText": "We are verifying the details you’ve submitted & will notify you on your eligibility to board Toofan within 5 seconds, on this site as well as through SMS or WhatsApp. Sit tight!",
		"text": "",
		"icon": "flight_win_check"
	},
	"win_check_waiting": {
		"headingText": "మరొక్క సారి పరీక్షించండి!",
		"subHeadingText": "",
		"text": "We are verifying the details you’ve submitted & will notify you on your eligibility to board Toofan within 3 business days, on this site as well as through SMS or WhatsApp. Sit tight!",
		"icon": "flight_win_check"
	},
	"valid_code": {
		"headingText": "వాహ్ తూఫాన్!",
		"subHeadingText": "మీరు {{points}} కిలోమీటర్ల ను సేకరించారు.మీరు తూఫాన్ యాబ్రాడ్ అవకాశానికి {{remaining_km}} కిలోమీటర్ల దూరంలో!",
		"text": "",
		"icon": "points_win_flight_icon.svg"
	},
	"total_limit_exceeded": {
		"headingText": "It’s the Bench for You!",
		"subHeadingText": "You have entered multiple invalid codes today. Come back tomorrow to try again.",
		"text": "",
		"icon": "warn_icon"
	},
	"already_win": {
		"headingText": "అప్పటికే గెలిచారా!",
		"subHeadingText": "మీరు అప్పటికే లక్కీ డ్రా లో గెలుచుకున్నారు",
		"text": "",
		"icon": "warn_icon"
	},
	"valid_code_limit_exceeded": {
		"headingText": "It’s the Bench for You!",
		"subHeadingText": "",
		"text": "You have entered multiple invalid codes today. Come back tomorrow to try again."
	},
	"claimed": {
		"headingText": "శుభాభినంధనలు!",
		"subHeadingText": "You’ve successfully claimed your ticket aboard Toofan Air with our fan squad",
		"text": "",
		"btnText": "Share",
		"icon": "claimed_icon"
	},
	"after_claimed": {
		"headingText": "మరొక్క సారి పరీక్షించండి!",
		"subHeadingText": "We are verifying the details you’ve submitted & will notify you on your eligibility to board Toofan within 10 business days, on this site as well as through SMS or WhatsApp. Sit tight!",
		"text": "",
		"btnText": "",
		"icon": "flight_win_check"
	},
	"claimed_reward": {
		"headingText": "శుభాభినంధనలు!",
		"subHeadingText": "You’ve successfully claimed your Toofani Reward",
		"text": "",
		"btnText": "Share",
		"icon": "claimed_icon"
	},
	"claim_now": {
		"headingText": "సమయం మించిపోతుంది!",
		"subHeadingText": "తూఫాన్ లో సీట్ ని పొందే మీ హక్కు {{timer}} కి ముగుస్తుంది",
		"text": "",
		"btnText": "ఇప్పుడు క్లైమ్ చేసుకోండి",
		"icon": "timer_icon"
	},
	"claim_expired": {
		"headingText": "మీరు రన్ అవుట్ అయ్యారు!",
		"subHeadingText": "మీ రివార్డ్‌పై ఉన్న గడువు ముగిసింది.",
		"text": "ఆశ కోల్పోవద్దు, మీరు ఇంకా ఆటలోనే ఉన్నారు. మీరు టూఫాన్‌లో తదుపరి సీటును గెలుచుకున్నారో లేదో తెలుసుకోవడానికి రేపు మళ్లీ రండి!",
		"icon": "said_icon"
	},
	"not_win_for_the_day": {
		"headingText": "అది మెయిడెన్ ఓవర్!",
		"subHeadingText": "ఆశ కోల్పోవద్దు, మీరు ఇంకా ఆటలోనే ఉన్నారు. మీరు టూఫాన్‌లో తదుపరి సీటును గెలుచుకున్నారో లేదో తెలుసుకోవడానికి రేపు మళ్లీ రండి!",
		"text": "",
		"icon": "said_icon"
	},
	"exit_claim_popup": {
		"headingText": "మీ మెడల్ ని తీసుకోండి",
		"subHeadingText": "మీ బహుమతుల మీద మీ హక్కు {{hours}} గంటలలో ముగుస్తుంది.",
		"text": "మీరు నిజంగా బయటకి వెళ్ళాలి అనుకుంటున్నారా?",
		"icon": "claim_expired_icon",
		"btnTextYes": "అవును",
		"btnTextNo": "కాదు",
		"confirm": true
	},
	"exit_next_win_for_new": {
		"headingText": "ఆపండి!",
		"subHeadingText": "ఈరోజు మీరు తరువాత విజేత కావచ్చు!",
		"text": "మీరు నిజంగా బయటకి వెళ్ళాలి అనుకుంటున్నారా?",
		"icon": "raise_hand_icon",
		"btnTextYes": "అవును",
		"btnTextNo": "కాదు",
		"confirm": true
	},
	"exit_next_win": {
		"headingText": "మీరు విజేత స్కోర్ కి చేరుకోబోతున్నారు.",
		"subHeadingText": "ఇంకొక విభిన్న కోడ్ ని ఎంటర్ చేసి గెలిచే అవకాశాన్ని పొందండి!",
		"text": "మీరు నిజంగా బయటకి వెళ్ళాలి అనుకుంటున్నారా?",
		"icon": "raise_hand_icon",
		"btnTextYes": "అవును",
		"btnTextNo": "కాదు",
		"confirm": true
	},
	"affiliate_signup_success": {
		"headingText": "బాగా చేసారు స్కిప్పర్",
		"subHeadingText": "",
		"text": "మీరు 100kms లను సేకరించారు",
		"icon": "right_bat"
	},
	"default": {
		"headingText": "Error!",
		"subHeadingText": "Not found!",
		"text": ""
	},
	"qualified_for_lucky_draw": {
		"headingText": "Already qualified!",
		"subHeadingText": "You have already qualified for the lucky draw",
		"text": ""
	}
}
