import React from 'react';
import { Button } from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';

const SocialShareButton = ({
	title,
	text,
	url,
	file,
	btnText = 'Share with friends',
	classesName = 'border-2 mt-3 font-medium text-1xl border-otherTextColor text-otherTextColor  p-2 font-thumbsUp w-2/3 mb-5 lg:text-4xxl lg:p-7 normal-case',
	winCode,
	sendCDPData,
	variant = 'outlined',
	onClickHandler = () => {},
}) => {
	const { t } = useTranslation();
	// Check if the Web Share API is available
	const isWebShareAvailable = () => {
		return navigator.share;
	};

	const getImageFile = async () => {
		const response = await fetch(file);
		const blob = await response.blob();
		return new File([blob], file.split('/').pop(), { type: blob.type });
	};

	const handleShare = async () => {
		onClickHandler && onClickHandler();
		if (isWebShareAvailable()) {
			try {
				let shareData = { title, text, url };

				// If sharing files (Level 2), include the files in the shareData
				if (file) {
					const fileObj = await getImageFile(); // Convert image path to File object
					if (navigator.canShare && navigator.canShare({ files: [fileObj] })) {
						shareData.files = [fileObj];
					}
				}

				await navigator.share(shareData);
				console.log('Content shared successfully');
			} catch (err) {
				console.error('Error sharing the content', err);
			}
		} else {
			// Fallback for browsers that do not support Web Share API
			alert(
				'Web Share API is not supported in your browser. Please manually copy the URL or use a desktop browser.'
			);
		}

		// CDP events
		if (winCode && winCode >= 2) {
			if (winCode === 2) {
				sendCDPData('Click', 'Share_Fancode999');
			} else {
				sendCDPData('Click', 'Share_Fancode499');
			}
		}
	};

	return (
		<Button
			onClick={handleShare}
			disabled={!isWebShareAvailable()}
			variant={variant}
			className={`${classesName}`}
		>
			{t(btnText)}
		</Button>
	);
};

export default SocialShareButton;
