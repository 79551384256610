import { useState, useCallback, useEffect, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate, Link } from 'react-router-dom';
import AddressBox from '../components/AddressBox';
import Button from '../components/Button';
import Heading from '../components/Heading';
import InputWithLabel from '../components/InputWithLabel';
import InputErrorMessage from '../components/InputErrorMessage';
import { useUserContext } from '../context/user-context';
import { AuthContext, useAuthContext } from '../context/auth-context';
import { useApiCall } from '../utils/useApiCall';
import Loader from '../components/Loader';
import AddAddressDialog from '../components/AddAddressDialog';
import { formatDate } from '../utils/common';
import DatePicker from '../components/DatePicker';
import { useCampaignContext } from '../context/campaign-context';
import { gaEvents } from '../utils/gaEvents';

const EditProfile = () => {
	const { t } = useTranslation(['translations', 'profile']);
	const navigate = useNavigate();

	const [openAddressForm, setOpenAddressForm] = useState(false);
	const [editAddress, setEditAddress] = useState({});

	const { userData } = useUserContext();
	const { token, isLoggedIn } = useAuthContext();
	const { setQueryUser } = useContext(AuthContext);
	const [apiErrorMessage, setApiErrorMessage] = useState('');

	const { isLoading, error: apiError, sendRequest } = useApiCall();
	useEffect(() => {
		if (!isLoggedIn) {
			navigate('/');
		}
	}, [isLoggedIn]);

	const yupSchema = useCallback(
		yup.object({
			full_name: yup.string().required(t('enter_name')),
			mobile_number: yup
				.string()
				.matches(/^\d{10}$/, t('mobile_error_message'))
				.min(10, t('mobile_error_message')),
			email_id: yup.string().nullable().email(t('email_error')),
			gender: yup.string().required('Please select a gender'),
			dob: yup
				.date(t('dob_error'))
				.required(t('dob_error'))
				.typeError(t('dob_error'))
				.test('is-over-18', t('dob_error'), function (value) {
					const eighteenYearsAgo = new Date();
					eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

					return value <= eighteenYearsAgo;
				}),
		}),
		[]
	);

	const {
		handleSubmit,
		formState: { errors },
		control,
		reset,
		register,
		setValue,
		watch,
	} = useForm({ resolver: yupResolver(yupSchema) });

	useEffect(() => {
		userData?.profile?.dob && setValue('dob', new Date(userData?.profile?.dob));
		userData?.profile?.gender && setValue('gender', userData?.profile?.gender);
	}, [userData?.profile?.dob]);

	useEffect(() => {
		setValue('full_name', watch('full_name')?.replace(/[^A-Za-z\s]/g, ''));
	}, [watch('full_name')]);

	const submitHandler = async (data) => {
		const { full_name, email_id, gender, dob } = data;

		try {
			const formattedDate = formatDate(dob);
			const response = await sendRequest(
				`${process.env.API_BASE_URL}user/profile`,
				'POST',
				JSON.stringify({
					name: full_name,
					email: email_id || undefined,
					gender,
					dob: formattedDate,
				}),
				{
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				}
			);

			if (response.success) {
				// GA Event
				window.dataLayer.push(gaEvents['TUICC24_CompletedProfile']);
				// setApiErrorMessage(response?.message);
				window.scrollTo({
					top: 0,
					behavior: 'smooth',
				});
				setQueryUser(true);
				navigate('/profile');
			} else {
				throw new Error(response.message);
			}
		} catch (error) {
			setApiErrorMessage(error?.message);
			console.error(`Claim form API error>>>`, error);
		}
	};
	const editAddressHandler = (data) => {
		setEditAddress(data);
		// setOpenAddressForm(!openAddressForm);
	};
	return (
		<div className="px-4 pt-4 pb-7 lg:px-36 lg:pt-9 lg:pb-20">
			<Heading text={t('profile:personal_info')} additionalClasses="mb-4" />
			<form onSubmit={handleSubmit(submitHandler)}>
				{isLoading && <Loader />}
				<div className="mb-4">
					<Controller
						name="full_name"
						control={control}
						defaultValue={userData.name}
						render={({ field: { ref, ...rest } }) => (
							<InputWithLabel
								{...rest}
								isValid={!errors?.full_name}
								label={`${t('profile:full_name')}*`}
								placeholder={t('profile:enter_full_name')}
							/>
						)}
					/>
					<InputErrorMessage text={errors?.full_name?.message} />
				</div>
				<div className="mb-4">
					<Controller
						readonly={true}
						name="mobile_number"
						control={control}
						defaultValue={userData.mobile_no}
						render={({ field: { ref, ...rest } }) => (
							<InputWithLabel
								{...rest}
								isValid={!errors?.mobile_number}
								label={`${t('mobile_number')}*`}
								placeholder={`${t('mobile_number')}`}
								disabled={!!userData.mobile_no}
							/>
						)}
					/>
					<InputErrorMessage text={errors?.mobile_number?.message} />
				</div>
				<div className="mb-4">
					<label className="text-black text-xl font-normal leading-6 mb-1.5">
						{t('gender')}*
					</label>
					<select
						{...register('gender', {
							required: true,
						})}
						className="bg-inputBg border-collapse p-4 rounded-lg w-full text-xl"
					>
						<option value="Male">{t('Male')}</option>
						<option value="Female">{t('Female')}</option>
						{/* <option value="Transgender">{t('transgender')}</option> */}
						<option value="Other">{t('Other')}</option>
					</select>
				</div>
				<div className="mb-4">
					<Controller
						name="email_id"
						control={control}
						defaultValue={userData.email}
						render={({ field: { ref, ...rest } }) => (
							<InputWithLabel
								{...rest}
								isValid={!errors?.email_id}
								label={`${t('profile:email_id_v1')}`}
								placeholder={`${t('profile:email_id_v1')}`}
							/>
						)}
					/>
					<InputErrorMessage text={errors?.email_id?.message} />
				</div>

				<div className="mb-6">
					<label className="text-black text-xl font-normal leading-6 mb-1.5">
						{`${t('profile:dob')}*`}
					</label>
					<Controller
						name="dob"
						control={control}
						defaultValue={''}
						render={({ field: { ref, value, onChange, ...rest } }) => (
							<DatePicker
								{...rest}
								isValid={!errors?.dob}
								placeholder="12-05-1992"
								selected={value || null}
								onChange={(date) => {
									// onChange(date);
									setValue('dob', date);
								}}
								type="date"
								maxDate={new Date()}
							/>
						)}
					/>
					<InputErrorMessage text={errors?.dob?.message} />
				</div>
				{/*<section className="mb-5">
					<AddressBox
						address={userData?.address || []}
						onAdd={() => setOpenAddressForm(!openAddressForm)}
						onEdit={editAddressHandler}
						onDelete={() => {}}
						btnClasses="text-bannerRedText"
					/>
				</section>*/}
				{apiErrorMessage && <InputErrorMessage text={t(apiErrorMessage)} />}
				<Button text={t('btn_submit')} />
			</form>

			{/*<AddAddressDialog
				open={openAddressForm}
				handleOpen={() => setOpenAddressForm(!openAddressForm)}
				editAddress={editAddress}
			/>*/}
			<Link
				to="https://017533-content-dev.s3.ap-south-1.amazonaws.com/privacypolicy/TU+2024-+Privacy+Policy.pdf"
				target="_blank"
			>
				<button className="mt-6">{t('privacy_policy')}</button>
			</Link>
		</div>
	);
};

export default EditProfile;
