import { useTranslation } from 'react-i18next';
import BannerBiryani from '../../public/banner_biryani_v1.webp';
import { useIsMobile } from '../utils/useIsMobile';

const ContestOver = () => {
	const { t } = useTranslation();

	const isMobile = useIsMobile();
	return (
		// <div className="flex flex-col justify-start items-center h-full bg-contest-over-bg lg:bg-contest-over-bg-dk bg-cover bg-no-repeat bg-center pb-5 px-4 pt-[8rem] lg:pt-[17rem] overflow-hidden">

		// 	<p className="text-white text-2xl font-medium leading-6 w-[60%] text-center mb-4 lg:w-[34%] lg:text-[3rem] lg:leading-[3.25rem]">
		// 		<span>{t('The ICC Toofani Tour Promo has now ended.')}</span>
		// 		<br />
		// 		<span>{t('For any queries, email us at')}{' '}
		// 			<a href="mailto:indiahelpline@coca-cola.com" className="underline">
		// 			indiahelpline@coca-cola.com
		// 			</a></span>
		// 	</p>

		// </div>
		<div className="bg-contest-over-bg lg:bg-contest-over-bg-dk bg-cover bg-no-repeat bg-center   w-full h-full flex flex-col justify-between items-center pt-[7.063rem] lg:pt-[7.063rem] pb-[3.125rem]">
			<div className="w-full text-center">
				{isMobile ? (
					<p className="text-white text-[2rem] lg:mb-2  text-center   lg:text-[1.75rem] font-medium leading-[2.25rem]">
						{'The ICC Toofani Tour Promo '}
						<br /> {'has now ended.'}
					</p>
				) : (
					<p className="text-white text-xl font-medium lg:mb-5   text-center   lg:text-[2.5rem] l">
						{'The ICC Toofani Tour Promo has now ended.'}
					</p>
				)}
				<p className="text-white  font-medium leading-6  text-center mb-[2.25rem] text-xl  lg:text-[1.75rem] ">
					<span className="text-base lg:text-2xl">
						{t('For any queries, email us at')}{' '}
					</span>
					<br />
					<a
						href="mailto:indiahelpline@coca-cola.com"
						className="text-xl lg:text-[1.75rem]"
					>
						indiahelpline@coca-cola.com
					</a>
				</p>
			</div>
			<div className="flex flex-col justify-center items-center w-full">
				{isMobile ? (
					<p className="text-base lg:text-xl text-white  font-medium px-4 text-center leading-5">
						{t('Meanwhile, explore Thums Up® Toofani Biryani Hunt and')}
						<br />
						{t(`vote for your favourite Biryani`)}
					</p>
				) : (
					<p className="text-base lg:text-xl text-white  font-medium px-4 text-center leading-5">
						{t(
							'Meanwhile, explore Thums Up® Toofani Biryani Hunt and vote for your favourite Biryani'
						)}
					</p>
				)}

				<div className="w-[90%] lg:w-[50%] border-2 border-white rounded-lg overflow-hidden">
					<a
						href="https://www.coke2home.com/toofanibiryanihunt/?utm_source=web_o&utm_medium=OWeb&utm_campaign=&utm_term=TU_ICC24&utm_content=link"
						target="_blank"
						className="TUICC24TUBiryaniBanner"
					>
						<img src={BannerBiryani} alt="banner" className="w-full" />
					</a>
				</div>
			</div>
		</div>
	);
};

export default ContestOver;
