{
	"win_1": {
		"headingText": "You’ve made it to the Toofani Fan Club!",
		"subHeadingText": "You won a seat Aboard Toofan to the ICC 2024 Games in the West Indies",
		"text": "",
		"icon": "flight_won_icon",
		"btnText": "এখন ক্লেম করুন",
		"btnSubText": "Claim within:",
		"timerDisplay": " {{datetime}}"
	},
	"win_2": {
		"headingText": "Congratulations!",
		"subHeadingText": "You have won Cricket Jersey worth ₹999 from FanCode!",
		"text": "",
		"icon": "win_icon",
		"btnText": "রিডিম করুন",
		"btnSubText": "Last date to redeem:",
		"timerDisplay": " {{datetime}}"
	},
	"win_3": {
		"headingText": "Congratulations!",
		"subHeadingText": "You have won Toofani merch worth ₹499 from FanCode!",
		"text": "",
		"icon": "win_icon",
		"btnText": "রিডিম করুন",
		"btnSubText": "Last date to redeem:",
		"timerDisplay": " {{datetime}}"
	},
	"win_check": {
		"headingText": "শুধু একটি চেক!",
		"subHeadingText": "We are verifying the details you’ve submitted & will notify you on your eligibility to board Toofan within 5 seconds, on this site as well as through SMS or WhatsApp. Sit tight!",
		"text": "",
		"icon": "flight_win_check"
	},
	"win_check_waiting": {
		"headingText": "শুধু একটি চেক!",
		"subHeadingText": "",
		"text": "We are verifying the details you’ve submitted & will notify you on your eligibility to board Toofan within 3 business days, on this site as well as through SMS or WhatsApp. Sit tight!",
		"icon": "flight_win_check"
	},
	"valid_code": {
		"headingText": "বাহ, তুফান!",
		"subHeadingText": "আপনি {{points}} কিমি সংগ্রহ করেছেন। আপনি তুফানে আপনার সুযোগ পাওয়া থেকে মাত্র {{remaining_km}} কিমি দূরে আছেন!",
		"text": "",
		"icon": "points_win_flight_icon.svg"
	},
	"total_limit_exceeded": {
		"headingText": "It’s the Bench for You!",
		"subHeadingText": "You have entered multiple invalid codes today. Come back tomorrow to try again.",
		"text": "",
		"icon": "warn_icon"
	},
	"already_win": {
		"headingText": "ইতিমধ্যে জিতেছেন!",
		"subHeadingText": "আপনি ইতিমধ্যেই লাকি ড্রতে জিতেছেন",
		"text": "",
		"icon": "warn_icon"
	},
	"valid_code_limit_exceeded": {
		"headingText": "It’s the Bench for You!",
		"subHeadingText": "",
		"text": "You have entered multiple invalid codes today. Come back tomorrow to try again."
	},
	"claimed": {
		"headingText": "Congratulations!",
		"subHeadingText": "You’ve successfully claimed your ticket aboard Toofan Air with our fan squad",
		"text": "",
		"btnText": "Share",
		"icon": "claimed_icon"
	},
	"after_claimed": {
		"headingText": "শুধু একটি চেক!",
		"subHeadingText": "আমরা আপনার জমা দেওয়া বিশদ বিবরণ যাচাই করছি এবং এই সাইটের পাশাপাশি এসএমএস বা হোয়াটসঅ্যাপের মাধ্যমে 10 কর্মদিবসের মধ্যে তুফানে যোগদানের জন্য আপনার যোগ্যতা সম্পর্কে আপনাকে অবহিত করব। প্রস্তুত হন!",
		"text": "",
		"btnText": "",
		"icon": "flight_win_check"
	},
	"claimed_reward": {
		"headingText": "Congratulations!",
		"subHeadingText": "You’ve successfully claimed your Toofani Reward",
		"text": "",
		"btnText": "Share",
		"icon": "claimed_icon"
	},
	"claim_now": {
		"headingText": "সময় শেষ হয়ে যাচ্ছে!",
		"subHeadingText": "তুফানে আপনার সিট ক্লেম করার সুযোগের মেয়াদ {{timer}}-তে শেষ হবে",
		"text": "",
		"btnText": "এখন ক্লেম করুন",
		"icon": "timer_icon"
	},
	"claim_expired": {
		"headingText": "আপনার সময় ফুরিয়ে গেছে!",
		"subHeadingText": "আপনার পুরস্কারের উপর আপনার ক্লেমের মেয়াদ শেষ হয়ে গেছে।",
		"text": "Don’t lose hope, you are still in the game.\nCome back again tomorrow to check if you have won the next seat on Toofan!",
		"icon": "said_icon"
	},
	"not_win_for_the_day": {
		"headingText": "“এটা মেডেন ওভার!",
		"subHeadingText": "আশা হারাবেন না, আপনি এখনও খেলায় আছেন। আগামীকাল ফিরে আসুন এবং দেখুন আপনি হারিকেনের একটি সিট জিতেছেন কিনা!",
		"text": "",
		"icon": "said_icon"
	},
	"exit_claim_popup": {
		"headingText": "আপনার মেডেল ক্লেম করুন",
		"subHeadingText": "আপনার প্রাইজের উপর আপনার ক্লেমের মেয়াদ {{hours}} ঘন্টার মধ্যে শেষ হয়ে যাবে।",
		"text": "আপনি কি প্রস্থান সম্পর্কে নিশ্চিত?",
		"icon": "claim_expired_icon",
		"btnTextYes": "হ্যাঁ",
		"btnTextNo": "না",
		"confirm": true
	},
	"exit_next_win_for_new": {
		"headingText": "“থামুন!",
		"subHeadingText": "আপনি আজ পরবর্তী বিজয়ী হতে পারেন!",
		"text": "আপনি কি প্রস্থান সম্পর্কে নিশ্চিত?",
		"icon": "raise_hand_icon",
		"btnTextYes": "হ্যাঁ",
		"btnTextNo": "না",
		"confirm": true
	},
	"exit_next_win": {
		"headingText": "“আপনি বিজয়ী স্কোর অর্জনের কাছাকাছি রয়েছেন!",
		"subHeadingText": "আরেকটি ইউনিক কোড লিখুন এবং জেতার সুযোগ পান!",
		"text": "আপনি প্রস্থান সম্পর্কে নিশ্চিত?",
		"icon": "raise_hand_icon",
		"btnTextYes": "হ্যাঁ",
		"btnTextNo": "না",
		"confirm": true
	},
	"affiliate_signup_success": {
		"headingText": "শাবাশ ক্যাপ্টেন",
		"subHeadingText": "",
		"text": "আপনি 100 কিলোমিটার সংগ্রহ করেছেন",
		"icon": "right_bat"
	},
	"default": {
		"headingText": "Error!",
		"subHeadingText": "Not found!",
		"text": ""
	},
	"qualified_for_lucky_draw": {
		"headingText": "Already qualified!",
		"subHeadingText": "You have already qualified for the lucky draw",
		"text": ""
	}
}
