import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Heading from '../components/Heading';
import { formatDate } from '../utils/common';
import { useApiCall } from '../utils/useApiCall';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader'; // Ensure this path is correct
import Pagination from '../components/Pagination'; // Ensure this path is correct
import Referrals from '../components/Referrals';
import { useAuthContext } from '../context/auth-context';
import Button from '../components/Button';

const ReferralList = ({ referFriendHandler }) => {
	const navigate = useNavigate();
	const { token, isLoggedIn } = useAuthContext();
	const [startDate, setStartDate] = useState(null);
	const [enableSendReminder, setEnableSendReminder] = useState(false);
	const [pageError, setPageError] = useState('');
	const [referrals, setReferrals] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [reminderError, setReminderError] = useState({
		status: false,
		message: '',
	});
	const { t, i18n } = useTranslation('affiliate');
	const { isLoading, error: apiError, sendRequest } = useApiCall();
	useEffect(() => {
		if (!isLoggedIn) {
			navigate('/');
		}

		// Fetch referrals when the component mounts and when startDate or currentPage changes
		isLoggedIn && fetchReferrals();
	}, [isLoggedIn, startDate, currentPage]);

	const fetchReferrals = async () => {
		let params = {
			page: currentPage,
			start_date: '',
			end_date: '',
		};
		if (startDate) {
			params.start_date = formatDate(startDate);
		}
		try {
			const response = await sendRequest(
				`${process.env.API_BASE_URL}referrals`,
				'POST',
				JSON.stringify(params),
				{
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				}
			);

			if (response.success) {
				setReferrals(response.data.referrals); // Adjust according to your actual API response structure
				setTotalPages(response.data.total);
			}
		} catch (error) {
			setPageError(error?.data?.message || error?.message);
			setReferrals([]); // Adjust according to your actual API response structure
			setTotalPages(0);
		}
	};
	const sendReminder = async () => {
		try {
			const response = await sendRequest(
				`${process.env.API_BASE_URL}send-reminder`,
				'POST',
				null,
				{
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				}
			);

			if (response.success) {
				setReminderError({
					status: true,
					message: 'Reminder send successfully',
				});
			}
		} catch (error) {
			setReminderError({
				status: true,
				message: error?.data?.message || error?.message,
			});
		}
	};

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		// Optionally, scroll to the top of the page or a specific element after changing the page
	};

	return (
		<div className="w-full pb-7  lg:pb-20">
			<Heading
				text={t('my_referrals')}
				additionalClasses="lg:mb-2 font-bold text-[28px]"
			/>

			{isLoading && <Loader />}
			{/* Render referrals list and pagination controls here */}
			<Referrals
				referrals={referrals}
				setEnableSendReminder={setEnableSendReminder}
			/>

			{/* Pagination Controls */}
			{totalPages > 0 ? (
				<Pagination
					currentPage={currentPage}
					totalPages={totalPages}
					onPageChange={handlePageChange}
				/>
			) : (
				<div className="mt-4 space-y-2">
					<div className="flex justify-center bg-inputBg px-4 py-2 h-39">
						{t('Referral not found')}
					</div>
				</div>
			)}
			<div
				className={`w-full flex justify-center items-center ${
					i18n.language === 'en' ? 'text-base' : 'text-xs'
				}  mt-5 `}
			>

			</div>
			<div className="mt-4 space-y-2">
				<div
					className={`flex justify-center  px-4 py-2 h-39 ${
						reminderError.status ? 'text-green' : 'text-danger'
					}`}
				>
					{t(reminderError.message)}
				</div>
			</div>
		</div>
	);
};

export default ReferralList;
