import UserIcon from "../../public/user_icon.svg";
import FlightIcon from "../../public/flight_takeoff_icon.svg";
import {useTranslation} from "react-i18next";

const Winners = ({winners}) => {
    const { t } = useTranslation(['translations', 'winner']);
  return (
      <div className="mt-4 space-y-2">
          <div className="flex justify-center items-center px-4 py-2 h-39 bg-otherTextColor rounded text-white">

              <div className="w-10 flex-shrink-0">{t("winner:avatar")}</div>
              <div className="w-1/3 ml-4 text-center">{t("winner:name")}</div>
              <div className="w-1/4 ml-4 text-center">{t("winner:phone_number")}</div>
              <div className="w-1/2  text-center">{t("winner:prize")}</div>
          </div>
          {winners.map((winner, index) => (
              <div key={index} className="flex items-center bg-inputBg px-4 py-2 h-39 text-base font-medium">
                  <div className="w-10 flex-shrink-0"> {/* Adjust width as needed */}
                      <img src={winner.profile ? winner.profile : UserIcon} alt="Avatar"
                           className="w-8 h-8 rounded-full"/>
                  </div>
                  <div className="w-1/3 ml-4 text-center">{winner.name}</div>
                  {/* Adjust width as needed */}
                  <div className="w-1/4 ml-4  text-center">{winner.mobile}</div>
                  {/* Adjust width as needed */}
                  <div className="flex-grow"></div>
                  {/* Spacer */}
                  <div className="w-1/2 flex justify-center items-center space-x-2"> {/* Adjust width as needed */}
                      {winner.reward.id === 1 && <img src={FlightIcon} alt="Flight" className="w-5 h-5"/>}
                      <div className="font-medium text-base">{t(winner.reward.detail)}</div>
                  </div>
              </div>
          ))}


      </div>
  );
}
export default Winners;