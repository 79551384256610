{
	"heading_h2": "पार्टनरशीप असावी तर अशी!",
	"heading_h3": "सहभागी होण्यासाठी तुमच्या मित्रांना आमंत्रित करा",
	"invite_code_text": "जेव्हा तुमचा मित्र साईन अप करेल आणि तुमचा आमंत्रण कोड वापरेल तेव्हा 100किमीज मिळवा",
	"friend_mobile_number": "तुमच्या मित्राचा मोबाईल क्रमांक एंटर करा",
	"friend_email": "तुमच्या मित्राचा ईमेल एंटर करा (पर्यायी)",
	"send_invite_btn": "सबमिट करा",
	"privacy_policy": "गोपनीयता धोरण",
	"icon": "right_bat",
	"mobile_required": "कृपया एक वैध नंबर एंटर करा",
	"email_optional": "कृपया एक वैध ईमेल एंटर करा",
	"Number already in use, Please refer another": "नंबर आधीपासून वापरात आहे, कृपया दुसरा रेफर करा",
	"thank_you": "धन्यवाद!",
	"thank_you_h3": "{{code}} या कोडसह तुमच्या मित्रास एक आमंत्रण पाठविले आहे",
	"thank_you_info_text": "एकदा का तुमच्या मित्राने साईन अप केले आणि तुमचा आमंत्रण कोड वापरला की मग 100किमीज मिळवा.",
	"enter_invite_code": "आमंत्रण कोड एंटर करा",
	"affiliate_signup_h3": "तुमच्या मित्राचा 6-अंकी आमंत्रण कोड एंटर करा आणि 100किमीज मिळवा",
	"affiliate_signup_btn": "सबमिट करा",
	"Please enter a valid code": "कृपया वैध कोड प्रविष्ट करा",
	"my_referrals": "माझे रेफरल्स",
	"accepted": "स्वीकारले",
	"pending": "प्रलंबित",
	"users": "वापरकर्ते",
	"status": "स्थिती",
	"send_reminder": "रिमाईंडर पाठवा",
	"refer_another": "अन्य व्यक्तीस रेफर करा",
	"Referral not found": "संदर्भ सापडला नाही"
}
