import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Logo from '../../public/logo.svg';
import { useAuthContext } from '../context/auth-context';
import { useCampaignContext } from '../context/campaign-context';
import { useUserContext } from '../context/user-context';
import BackArrow from '../../public/back_arrow.svg';

const MobileHeader = ({ setOpenContact }) => {
	const navigate = useNavigate();
	const location = useLocation();

	return (
		<>
			{location.pathname === '/winner-list' ? (
				<div className="w-6" onClick={() => navigate('/contest-over')}>
					<img src={BackArrow} className="w-full" />
				</div>
			) : (
				<div></div>
			)}
			<div className="w-[3.063rem] ">
				<img src={Logo} alt="LOGO" onClick={() => {}} className="w-full" />
			</div>
			<div></div>
		</>
	);
};

export default MobileHeader;
